/* eslint-disable @typescript-eslint/naming-convention */
// Package Imports
import axios from 'axios';
import { type HttpError } from '@refinedev/core';
// import useRequestList from 'core/utils/useRequestDetails';
// import { useTokenContext } from 'contexts/resource/ResourceContext';

export const axiosInstance = axios.create();

// axiosInstance.interceptors.request.use((request) => {
//   const { token, orgId } = useRequestList()
//   if (token) {
//     request.headers.Authorization = `Bearer ${token}`;
//     request.headers.orgId = orgId
//   } else {
//     request.headers = {
//       Authorization: `Bearer ${token}`,
//     };
//   }
//   return request;
// });

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };
    return Promise.reject(customError);
  }
);

export default axiosInstance; // Export the axiosInstance
