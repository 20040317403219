// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';

// Lazy-loaded Components
const InputSourceCreate = lazy(() => import('pages/inputSource/create'));
const InputSourceList = lazy(() => import('pages/inputSource/list'));
const InputSourceViewEdit = lazy(() => import('pages/inputSource/viewEdit'));

const InputSourceRoute = (
  <Route path={routeName.dataSourceManagement}>
    <Route
      path={routeName.inputSource}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.INPUTLISTITEM_VIEW,
            PrivateRouteConst.SELF_INPUTLISTITEM_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <InputSourceList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.inputSource}/create`}
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_INPUT}>
          <Suspense fallback={<Loading />}>
            <InputSourceCreate />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.inputSource}/show/:id`}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.INPUTLISTITEM_VIEW,
            PrivateRouteConst.SELF_INPUTLISTITEM_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <InputSourceViewEdit isOnlyShow={true} />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.inputSource}/edit/:id`}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.INPUTLISTITEM_EDIT,
            PrivateRouteConst.SELF_INPUTLISTITEM_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <InputSourceViewEdit isOnlyShow={false} />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default InputSourceRoute;
