// Package Imports
import { FileAddOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

const InputSourceResource = {
  name: resourceName.inputSource,
  identifier: ModuleIdentifierConst.INPUTSOURCE_MANAGEMENT,
  list: `${resourceName.dataSourceManagement}/${resourceName.inputSource}`,
  create: `${resourceName.dataSourceManagement}/${resourceName.inputSource}/create`,
  show: `${resourceName.dataSourceManagement}/${resourceName.inputSource}/show/:id`,
  edit: `${resourceName.dataSourceManagement}/${resourceName.inputSource}/edit/:id`,
  options: { label: 'Input Source', icon: <FileAddOutlined /> },
};

export default InputSourceResource;
