// Project Imports
import DataSourceManagementResource from 'core/resources/DataSourceManagement';
import InputSourceResource from 'core/resources/InputSource';

import MasterSourceResource from 'core/resources/MasterManagement';
import CustomColumnsResource from 'core/resources/CustomColumns';

import UnifiedSourceResource from 'core/resources/UnifiedSourceManagement';

const resources = [
  DataSourceManagementResource,
  InputSourceResource,
  MasterSourceResource,
  UnifiedSourceResource,

  CustomColumnsResource,
];

export default resources;
