// Package Imports
import React from 'react';
import { Create, SaveButton, useForm } from '@refinedev/antd';
import { Card } from 'antd';

const Register = () => {
  const { saveButtonProps } = useForm({
    resource: 'consolidated-application-users',
  });

  return (
    <Card>
      <Create
        title=""
        goBack={false}
        saveButtonProps={saveButtonProps}
        footerButtons={() => <SaveButton {...saveButtonProps}>Save</SaveButton>}
      >
        <h1 className="flex justify-center items-center">Registration</h1>
      </Create>
    </Card>
  );
};

export default Register;
