// Package Imports
import { FileAddOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

const MasterSourceResource = {
  name: resourceName.masterSource,
  identifier: ModuleIdentifierConst.MASTERSOURCE_MANAGEMENT,
  list: `${resourceName.dataSourceManagement}/${resourceName.masterSource}`,
  create: `${resourceName.dataSourceManagement}/${resourceName.masterSource}/create`,
  show: `${resourceName.dataSourceManagement}/${resourceName.masterSource}/show/:id`,
  edit: `${resourceName.dataSourceManagement}/${resourceName.masterSource}/edit/:id`,
  options: { label: 'Master Source', icon: <FileAddOutlined /> },
};

export default MasterSourceResource;
