// package imports
import { FolderOutlined } from '@ant-design/icons';
// project imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const CustomColumnsResource: ResourceItem = {
  name: resourceName.columns,
  identifier: resourceName.columns,
  list: `${resourceName.columns}`,
  show: `${resourceName.columns}/show/:id`,
  create: `${resourceName.columns}/create`,
  options: { label: 'Custom Column', icon: <FolderOutlined /> },
  meta: {
    hide: true,
    parent: resourceName.inputSource,
  },
};

export default CustomColumnsResource;
