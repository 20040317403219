// Package Imports
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Authenticated } from '@refinedev/core';
import {
  CatchAllNavigate,
  NavigateToResource,
} from '@refinedev/react-router-v6';
import { ThemedLayoutV2 } from '@refinedev/antd';

// Project Imports
import DashboardRoute from 'core/routes/DashboardRoute';
import ErrorRoute from 'core/routes/ErrorRoute';
import LoginRoute from 'core/routes/LoginRoute';
import Header from 'components/header';
import RegisterRoute from 'core/routes/Register';
import CustomSider from 'components/sider/sider';
import routeName from 'core/common/routeName';
import resourceName from 'core/common/resourceName';
import InputSourceRoute from 'core/routes/InputSource';
import CustomColumnsRoute from 'core/routes/CustomColumnsRoute';
import MasterSourceRoute from 'core/routes/MasterRoute';
import OutputSourceRoute from 'core/routes/OutputSource';
import UnifiedCustomColumnsRoute from 'core/routes/UnifiedCustomColumnsRoute';
import ProfileRoute from 'core/routes/ProfileRoute';

const AppRoutes: React.FC = () => (
  <Routes>
    <Route
      element={
        <Authenticated fallback={<CatchAllNavigate to={routeName.login} />}>
          <ThemedLayoutV2
            Header={() => <Header />}
            Sider={() => <CustomSider />}
          >
            <Outlet />
          </ThemedLayoutV2>
        </Authenticated>
      }
    >
      <Route
        index
        element={<NavigateToResource resource={resourceName.dashboard} />}
      />
      {DashboardRoute}
      {ErrorRoute}
      {RegisterRoute}
      {InputSourceRoute}
      {CustomColumnsRoute}
      {UnifiedCustomColumnsRoute}
      {MasterSourceRoute}
      {OutputSourceRoute}
      {ProfileRoute}
    </Route>
    {ErrorRoute}
    {RegisterRoute}
    <Route
      element={
        <Authenticated fallback={<Outlet />}>
          <NavigateToResource />
        </Authenticated>
      }
    >
      {LoginRoute}
    </Route>
  </Routes>
);

export default AppRoutes;
