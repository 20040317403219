// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

// Lazy-loaded Components
const OutputSourceList = lazy(() => import('pages/unifiedSource/list'));
const JoinColumns = lazy(
  () => import('pages/unifiedSource/joinColumns/JoinColumns')
);
const JoinEditColumns = lazy(
  () => import('pages/unifiedSource/joinColumns/JoinEditColumns')
);
const DesignateUnified = lazy(
  () => import('pages/unifiedSource/designateUnified')
);
const EditDesignateUnified = lazy(
  () => import('pages/unifiedSource/editDesignateUnified')
);

const OutputSourceRoute = (
  <Route path={routeName.dataSourceManagement}>
    <Route
      path={routeName.unifiedSource}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.UNIFIEDLISTITEM_VIEW,
            PrivateRouteConst.SELF_UNIFIEDLISTITEM_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <OutputSourceList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.unifiedSource}/create`}
      element={
        <PrivateRoute
          name={[PrivateRouteConst.ALLOW_JOIN, PrivateRouteConst.ALLOW_MERGE]}
        >
          <Suspense fallback={<Loading />}>
            <JoinColumns />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.unifiedSource}/edit/:id`}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.UNIFIEDLISTITEM_EDIT,
            PrivateRouteConst.SELF_UNIFIEDLISTITEM_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <JoinEditColumns pagetype="Edit" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.unifiedSource}/show/:id`}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.UNIFIEDLISTITEM_VIEW,
            PrivateRouteConst.SELF_UNIFIEDLISTITEM_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <JoinEditColumns pagetype="Show" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.unifiedSource}/create/designateUnified/:id`}
      element={
        <PrivateRoute name={PrivateRouteConst.ALLOW_JOIN}>
          <Suspense fallback={<Loading />}>
            <DesignateUnified />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.unifiedSource}/edit/designateUnified/:id`}
      element={
        <PrivateRoute name={PrivateRouteConst.ALLOW_JOIN}>
          <Suspense fallback={<Loading />}>
            <EditDesignateUnified pagetype="Edit" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.unifiedSource}/show/designateUnified/:id`}
      element={
        <PrivateRoute name={PrivateRouteConst.ALLOW_JOIN}>
          <Suspense fallback={<Loading />}>
            <EditDesignateUnified pagetype="Show" />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default OutputSourceRoute;
