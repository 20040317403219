// Package Imports
import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { createRoot } from 'react-dom/client';
// Project Imports
import keycloak from 'keycloak/keyCloak';
import App from 'App';
import 'i18n';
import TokenProvider from 'contexts/resource/ResourceContext';
import InputSourceProvider from 'contexts/resource/inputSourceContext/InputSourceContext';
import MasterSourceProvider from 'contexts/resource/masterSourceContext/MasterSourceContext';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <TokenProvider>
      <InputSourceProvider>
        <MasterSourceProvider>
          <ReactKeycloakProvider authClient={keycloak}>
            <React.StrictMode>
              <React.Suspense fallback="loading">
                <App />
              </React.Suspense>
            </React.StrictMode>
          </ReactKeycloakProvider>
        </MasterSourceProvider>
      </InputSourceProvider>
    </TokenProvider>
  );
} else {
  console.error('Root element with id');
}
