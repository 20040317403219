const PrivateRouteConst = {
  INPUTLISTITEM_VIEW: 'allInputListItemView',
  INPUTLISTITEM_EDIT: 'allInputListItemEdit',
  INPUTLISTITEM_DELETE: 'allInputListItemDelete',
  SELF_INPUTLISTITEM_VIEW: 'selfInputListItemView',
  SELF_INPUTLISTITEM_EDIT: 'selfInputListItemEdit',
  SELF_INPUTLISTITEM_DELETE: 'selfInputListItemDelete',
  CREATE_INPUT: 'createInput',

  ALLOW_JOIN: 'allowJoin',
  ALLOW_MERGE: 'allowMerge',

  UNIFIEDLISTITEM_VIEW: 'allUnifiedListItemView',
  UNIFIEDLISTITEM_EDIT: 'allUnifiedListItemEdit',
  UNIFIEDLISTITEM_DELETE: 'allUnifiedListItemDelete',
  SELF_UNIFIEDLISTITEM_VIEW: 'selfUnifiedListItemView',
  SELF_UNIFIEDLISTITEM_EDIT: 'selfUnifiedListItemEdit',
  SELF_UNIFIEDLISTITEM_DELETE: 'selfUnifiedListItemDelete',
  CREATE_UNIFIED: 'createUnified',

  MASTERLISTITEM_VIEW: 'allMasterListItemView',
  MASTERLISTITEM_EDIT: 'allMasterListItemEdit',
  MASTERLISTITEM_DELETE: 'allMasterListItemDelete',
  SELF_MASTERLISTITEM_VIEW: 'selfMasterListItemView',
  SELF_MASTERLISTITEM_EDIT: 'selfMasterListItemEdit',
  SELF_MASTERLISTITEM_DELETE: 'selfMasterListItemDelete',
  CREATE_MASTER: 'createMaster',

  INPUT_CUSTOM_COLUMN_ITEM_VIEW: 'allInputCustomColumnView',
  INPUT_CUSTOM_COLUMN_ITEM_EDIT: 'allInputCustomColumnEdit',
  INPUT_CUSTOM_COLUMN_ITEM_DELETE: 'allInputCustomColumnDelete',
  SELF_INPUT_CUSTOM_COLUMN_ITEM_VIEW: 'selfInputCustomColumnView',
  SELF_INPUT_CUSTOM_COLUMN_ITEM_EDIT: 'selfInputCustomColumnEdit',
  SELF_INPUT_CUSTOM_COLUMNINPUT_ITEM_DELETE: 'selfInputCustomColumnDelete',
  CREATE_INPUT_CUSTOM_COLUMN: 'createInputCustomColumn',

  UNIFIED_CUSTOM_COLUMN_ITEM_VIEW: 'allUnifiedCustomColumnView',
  UNIFIED_CUSTOM_COLUMN_ITEM_EDIT: 'allUnifiedCustomColumnEdit',
  UNIFIED_CUSTOM_COLUMN_ITEM_DELETE: 'allUnifiedCustomColumnDelete',
  SELF_UNIFIED_CUSTOM_COLUMN_ITEM_VIEW: 'selfUnifiedCustomColumnView',
  SELF_UNIFIED_CUSTOM_COLUMN_ITEM_EDIT: 'selfUnifiedCustomColumnEdit',
  SELF_UNIFIED_CUSTOM_COLUMNINPUT_ITEM_DELETE: 'selfUnifiedCustomColumnDelete',
  CREATE_UNIFIED_CUSTOM_COLUMN: 'createUnifiedCustomColumn',
};

export default PrivateRouteConst;
