// Package Imports
import React from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import Register from 'pages/register';
import routeName from 'core/common/routeName';

const RegisterRoute = (
  <Route path={routeName.register} element={<Register />} />
);

export default RegisterRoute;
