const XlsType = 'application/vnd.ms-excel';
const XlsWpsType = 'application/wps-office.xls';
const XlsxType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const CsvType = 'text/csv';
const dateFormat = 'DD/MM/YYYY';
const dateFormatWithHyphen = 'YYYY-MM-DD';
const success = 'success';
const info = 'info';
const warning = 'warning';
const error = 'error';
const initialValuesQueryBuilder = {
  caseList: [],
  elseType: '',
  elseValue: '',
};

const constants = {
  XlsType,
  XlsWpsType,
  XlsxType,
  CsvType,
  dateFormat,
  dateFormatWithHyphen,
  success,
  info,
  warning,
  error,
  initialValuesQueryBuilder,
};

export default constants;
