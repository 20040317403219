// Package Imports
import React, { createContext, useContext, useEffect, useState } from 'react';
// Project Imports
import { InputSourceContextType } from 'contexts/resource/inputSourceContext/inputSourceContextType';

const InputSourceContext = createContext<InputSourceContextType>({
  selectFile: null,

  setSelectFile() {
    throw new Error('setCurrent function is not implemented');
  },
  current: 0,
  setCurrent() {
    throw new Error('setCurrent function is not implemented');
  },
  isFirstStepCompleted: false,
  setIsFirstStepCompleted() {
    throw new Error('setIsFirstStepCompleted function is not implemented');
  },
  isSecondStepCompleted: false,
  setIsSecondStepCompleted() {
    throw new Error('setIsSecondStepCompleted function is not implemented');
  },
  setInputSourceId() {
    throw new Error('setCurrent function is not implemented');
  },
  inputSourceId: null,
  setInputSourceFileData() {
    throw new Error('setInputSourceFileData function is not implemented');
  },
  inputSourceFileData: null,
  selectFileObj: null,
  setSelectFileObj() {
    throw new Error('setSelectFileObj function is not implemented');
  },
});

export function useInputSourceContext() {
  return useContext(InputSourceContext);
}

const InputSourceProvider = ({ children }: { children: React.ReactNode }) => {
  const [current, setCurrent] = useState<any>(() => {
    const storedCurrent = localStorage.getItem('current');
    return Number(storedCurrent) || 0;
  });
  const [selectFile, setSelectFile] = useState(
    () => localStorage.getItem('selectFile') || ''
  );
  const [selectFileObj, setSelectFileObj] = useState(() => {
    const storedSelectFileObj = localStorage.getItem('selectFileObj');
    return storedSelectFileObj ? JSON.parse(storedSelectFileObj) : {};
  });
  const [inputSourceId, setInputSourceId] = useState<any>(
    () => localStorage.getItem('inputSourceId') || null
  );
  const [inputSourceFileData, setInputSourceFileData] = useState(() => {
    const storedinputSourceFile = localStorage.getItem('inputSourceFileData');
    return storedinputSourceFile ? JSON.parse(storedinputSourceFile) : '';
  });
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState(
    () => localStorage.getItem('isFirstStepCompleted') === 'true'
  );
  const [isSecondStepCompleted, setIsSecondStepCompleted] = useState(
    () => localStorage.getItem('isSecondStepCompleted') === 'true'
  );

  useEffect(() => {
    localStorage.setItem('current', current);
  }, [current]);

  useEffect(() => {
    localStorage.setItem('selectFile', selectFile);
  }, [selectFile]);

  useEffect(() => {
    localStorage.setItem('selectFileObj', JSON.stringify(selectFileObj));
  }, [selectFileObj]);

  useEffect(() => {
    localStorage.setItem('inputSourceId', inputSourceId);
  }, [inputSourceId]);

  useEffect(() => {
    console.log(inputSourceFileData, 'inputSourceFileData');
    localStorage.setItem(
      'inputSourceFileData',
      JSON.stringify(inputSourceFileData)
    );
  }, [inputSourceFileData]);

  useEffect(() => {
    localStorage.setItem(
      'isFirstStepCompleted',
      isFirstStepCompleted === true ? 'true' : 'false'
    );
  }, [isFirstStepCompleted]);

  useEffect(() => {
    localStorage.setItem(
      'isSecondStepCompleted',
      isSecondStepCompleted === true ? 'true' : 'false'
    );
  }, [isSecondStepCompleted]);

  return (
    <InputSourceContext.Provider
      value={{
        selectFile,
        setSelectFile,
        selectFileObj,
        setSelectFileObj,
        current,
        setCurrent,
        setInputSourceId,
        inputSourceId,
        isFirstStepCompleted,
        setIsFirstStepCompleted,
        isSecondStepCompleted,
        setIsSecondStepCompleted,
        setInputSourceFileData,
        inputSourceFileData,
      }}
    >
      {children}
    </InputSourceContext.Provider>
  );
};

export default InputSourceProvider;
