// Package Imports
import { FileAddOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

const UnifiedSourceResource = {
  name: resourceName.unifiedSource,
  identifier: ModuleIdentifierConst.OUTPUTSOURCE_MANAGEMENT,
  list: `${resourceName.dataSourceManagement}/${resourceName.unifiedSource}`,
  create: `${resourceName.dataSourceManagement}/${resourceName.unifiedSource}/create`,
  designateUnified: `${resourceName.dataSourceManagement}/${resourceName.unifiedSource}/create/designateUnified/:id`,
  editdesignateUnified: `${resourceName.dataSourceManagement}/${resourceName.unifiedSource}/edit/designateUnified/:id`,
  showdesignateUnified: `${resourceName.dataSourceManagement}/${resourceName.unifiedSource}/show/designateUnified/:id`,
  show: `${resourceName.dataSourceManagement}/${resourceName.unifiedSource}/show/:id`,
  edit: `${resourceName.dataSourceManagement}/${resourceName.unifiedSource}/edit/:id`,
  options: { label: 'Unified Data', icon: <FileAddOutlined /> },
};

export default UnifiedSourceResource;
