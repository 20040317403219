// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';

// Lazy-loaded Components
const MasterSourceList = lazy(() => import('pages/master/list'));
const MasterCreate = lazy(() => import('pages/master/create'));
const MasterSourceViewEdit = lazy(() => import('pages/master/viewEdit'));

const MasterSourceRoute = (
  <Route path={routeName.dataSourceManagement}>
    <Route
      path={routeName.masterSource}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.MASTERLISTITEM_VIEW,
            PrivateRouteConst.SELF_MASTERLISTITEM_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <MasterSourceList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.masterSource}/create`}
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_MASTER}>
          <Suspense fallback={<Loading />}>
            <MasterCreate />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.masterSource}/show/:id`}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.MASTERLISTITEM_VIEW,
            PrivateRouteConst.SELF_MASTERLISTITEM_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <MasterSourceViewEdit isOnlyShow={true} />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.masterSource}/edit/:id`}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.MASTERLISTITEM_EDIT,
            PrivateRouteConst.SELF_MASTERLISTITEM_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <MasterSourceViewEdit isOnlyShow={false} />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default MasterSourceRoute;
