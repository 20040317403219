// Project Imports
import routeName from 'core/common/routeName';
import keycloak from 'keycloak/keyCloak';

const logout = async () => {
  try {
    await localStorage.clear();
    await keycloak.logout({
      redirectUri: window.location.origin,
    });

    return {
      success: true,
      redirectTo: routeName.login,
    };
  } catch (error) {
    return {
      success: false,
      error: new Error('Logout failed'),
    };
  }
};

export default logout;
