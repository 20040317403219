// Package Imports
import React, { ReactNode } from 'react';

// Project Imports
import usePermission from 'core/utils/usePermission';
import { useTokenContext } from 'contexts/resource/ResourceContext';
import AuthError from 'core/utils/AuthError';
import { useTranslate } from '@refinedev/core';

type PrivateRouteProps = {
  children: ReactNode;
  name: string | string[];
};

const PrivateRoute = ({ children, name }: PrivateRouteProps) => {
  const checkPermission = usePermission();
  const t = useTranslate();
  const { token } = useTokenContext();
  let allowed = false;

  const names = Array.isArray(name) ? name : name.split(',');

  if (token && names.some(checkPermission)) {
    allowed = true;
  }

  let contentToShow: ReactNode;
  if (allowed) {
    contentToShow = children;
  } else if (token) {
    contentToShow = <AuthError message={t('pages.error.unAuthorized')} />;
  } else {
    contentToShow = <AuthError message={t('pages.error.unAuthenticated')} />;
  }
  return contentToShow;
};

export default PrivateRoute;
