// Package Imports
import { ProjectOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

const DataSourceManagementResource: ResourceItem = {
  name: resourceName.dataSourceManagement,
  identifier: ModuleIdentifierConst.DATA_MANAGEMENT,
  options: {
    label: 'Data Source Management',
    icon: <ProjectOutlined />,
  },
};

export default DataSourceManagementResource;
