const dashboard = '/dashboard';
const login = '/login';
const error = '/error';
const register = 'register';
const dataSourceManagement = '/data-management';
const inputSource = 'input-source';
const unifiedSource = 'unified-source';
const masterSource = 'master-source';
const inputColumns = 'input-columns';
const unifiedColumns = 'unified-columns';
const profile = '/profile';

const routeName = {
  dashboard,
  login,
  error,
  register,
  dataSourceManagement,
  inputSource,
  unifiedSource,
  inputColumns,
  unifiedColumns,
  masterSource,
  profile,
};

export default routeName;
