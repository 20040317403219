const dashboard = 'dashboard';
const login = 'login';
const error = 'error';
const register = 'register';
const dataSourceManagement = 'data-management';
const inputSource = 'input-source';
const masterSource = 'master-source';
const unifiedSource = 'unified-source';
const users = 'users';
const columns = 'columns';
const inputSourceConnection = 'input_source_connections';
const unifiedSourceMaster = 'unified_source_master';
const masterSourceConnection = 'master_data_source_connections';
const unifiedsourcemaster = 'unified_source_master';
const profile = 'profile';

const resourceName = {
  dashboard,
  login,
  error,
  register,
  dataSourceManagement,
  inputSource,
  masterSource,
  unifiedSource,
  users,
  columns,
  inputSourceConnection,
  unifiedSourceMaster,
  masterSourceConnection,
  unifiedsourcemaster,
  profile,
};

export default resourceName;
